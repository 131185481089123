import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginPage from './pages/account/login/login.page';
import TasksPage from './pages/task/tasks/tasks.page';
import TaskDetailsPage from './pages/task/taskDetails/taskDetails.page';
import ResetPasswordPage from './pages/account/resetPassword/resetPassword.page';
import ChangePasswordPage from './pages/account/changePassword/changePassword.page';
import ProtectedRoute from './ProtectedRoute';
import TasksHistoryPage from './pages/task/tasksHistory/tasksHistory.page';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/user/security',
    element: <ChangePasswordPage />,
  },
  {
    path: '/password/reset',
    element: <ResetPasswordPage />,
  },
  {
    path: '/tasks',
    element: (
      <ProtectedRoute>
        <TasksPage />
      </ProtectedRoute>
    ),
  },
  {
    path: 'task/details/:id',
    element: (
      <ProtectedRoute>
        <TaskDetailsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/tasks/history',
    element: (
      <ProtectedRoute>
        <TasksHistoryPage />
      </ProtectedRoute>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

reportWebVitals();

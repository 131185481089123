import { AxiosResponse } from 'axios';
import { apiClient } from '../infrastructure/api/apiClient';

export const getAssetsCatalog = () => {
  return apiClient.get('/assets').then(response => {
    return response.data;
  });
};

export const getAssetById = (assetId: string) => {
  return apiClient.get(`/assets/${assetId}`).then(response => {
    return response.data;
  });
};

export const assigneAssetToEmployee = (payload: any) => {
  return apiClient
    .post('/assets/assign', payload)
    .then((response: AxiosResponse<any, any>) => {
      return response.data;
    });
};

/* eslint-disable prettier/prettier */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CalculateTaskStatusColor } from './helpers/CalculateTaskStatusColor';
import Task from '../../types/task/task.type';
import {
  ActionType,
  getReadableActionType,
} from '../../utils/action-types.utils';
import { transformTaskStatus } from '@rocket-desert/common-lib';

const ProcessPercentage = styled.div<{ color?: string }>`
  width: 100px;
  padding: 5px;
  border-radius: 5px;
  background-color: ${props => props.color};
  text-align: center;
  font-size: 12px;
  color: #fff;
`;

const FirstTd = styled.td`
  width: 500px;
`;

const FixedTd = styled.td`
  max-width: 20%;
`;

const OnboardingTableIteam: React.FunctionComponent<Task> = task => {
  const navigate = useNavigate();
  const handleNavgatefunc = async (id: number) => {
    navigate('/task/details/' + id);
  };

  return (
    <tr key={task.id} onClick={() => handleNavgatefunc(task.id)}>
      <FirstTd>{task.title}</FirstTd>
      <FixedTd>{task.taskType}</FixedTd>
      <FixedTd>
        {task.employee ? task.employee.firstName : null}{' '}
        {task.employee ? task.employee.lastName : null}
      </FixedTd>
      {task.action ? (
        <>
          <FixedTd>
            {getReadableActionType(task.action.type as ActionType)}
          </FixedTd>
        </>
      ) : (
        <FixedTd>Manual</FixedTd>
      )}
      <FixedTd>
        <ProcessPercentage color={CalculateTaskStatusColor(task.taskStatus)}>
          {transformTaskStatus(task.taskStatus)}
        </ProcessPercentage>
      </FixedTd>
      <FixedTd> {task.dueDate} </FixedTd>
    </tr>
  );
};

export default OnboardingTableIteam;

import React from 'react';
import { useState } from 'react';
import ITask from '../../../types/task/task.type';
import DashboardLayout from '../../../layouts/dashboard/dashboard.layout';
import TaskListFeature from '../../../features/tasks/task-list.feature';
import { getTasksHistory } from '../../../services/task/task.service';

function TasksHistorydPage() {
  const [tasks, setTasks] = useState<ITask[]>(() => []);

  React.useEffect(() => {
    const fetchTasks = async () => {
      const t = await getTasksHistory();
      setTasks(t);
    };
    fetchTasks();
  }, []);
  return (
    <DashboardLayout>
      <div className="task-wrap">
        <TaskListFeature title="Tasks history" showTitle={true} items={tasks} />

        {!tasks.length ? <h2>There is no any completed tasks</h2> : null}
      </div>
    </DashboardLayout>
  );
}

export default TasksHistorydPage;

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import IconTextComponent from '../../components/IconTextComponent/IconTextComponent.component';
import ProfileIconComponent from '../../components/ProfileIcon/ProfileIconComponent.component';
import { getCurrentUser } from '../../services/auth/auth.service';
import '../index/styles/index.style.css';
import './styles/dashboard.style.css';

interface Props {
  children: React.ReactNode;
}

const DashboardLayout: React.FunctionComponent<Props> = (props: Props) => {
  const navigate = useNavigate();
  const user = getCurrentUser();
  const handleLink = async (route: string) => {
    navigate(route);
  };

  return (
    <>
      <div className="page-container">
        <div className="content-wrap">
          <div className="wrapper">
            <div className="settings-wrapper"></div>
            <div className="sidebar-wrapper">
              <div className="sidebar-heading">
                <h1>Rocket Desert</h1>
                <span>{user.organization.name}</span>
              </div>
              <div className="list-group">
                <ul className="list-items">
                  <li>
                    <IconTextComponent
                      key="tasks"
                      onClick={() => handleLink('/tasks')}
                      symbol="T"
                      text="Tasks"
                      color="#406eb8"
                    />
                  </li>
                  <li>
                    <IconTextComponent
                      key="tasksHistory"
                      onClick={() => handleLink('/tasks/history')}
                      symbol="H"
                      text="History"
                      color="#406eb8"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="header">
                <ProfileIconComponent
                  symbol={`${user.firstName[0]}${user.lastName[0]}`}
                />
              </div>
              <div className="children-pad">{props.children}</div>
            </div>
          </div>
        </div>
        <footer>
          <p className="copyright">
            © Copyright {new Date().getFullYear()} Rocket Desert Inc. All
            rights reserved.
          </p>
        </footer>
      </div>
    </>
  );
};

export default DashboardLayout;

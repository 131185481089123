import * as React from 'react';
import './styles/index.style.css';

interface Props {
  children: React.ReactNode;
}

const IndexLayout: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <div className="page-container">
        <div className="content-wrap">
          <div className="wrapper">
            <div className="leftSideWrapper">
              <div className="sideWrapper">
                <div className="logo">Rocket Desert</div>
                <div className="description">
                  Maximizing Business Potential through Superior Onboarding
                  Experiences
                </div>
              </div>
            </div>
            <div className="rightSide">{props.children}</div>
          </div>
        </div>
        <p className="copyright">
          © Copyright {new Date().getFullYear()} Rocket Desert. All rights
          reserved.
          <a
            className="legalinks"
            href="https://rocketdesert.com/terms-of-service"
          >
            Terms & Conditions
          </a>{' '}
          and <span> </span>
          <a
            className="legalinks"
            href="https://rocketdesert.com/privacy-policy"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </>
  );
};

export default IndexLayout;
